<template>
  <fragment>
    <PageFollowWithWsSelection
      v-if="workspaceSelectorOnPages"
      :item="item" />
    <app-action-btn
      v-else
      data-test="follow_collection_button"
      :loading="isLoading"
      :access="canFollowPage"
      :show-tooltip="!canFollowPage"
      bottom
      @click="onFollowActions">
      <template #iconWrapper>
        <v-icon
          size="20"
          color="lightBlack">
          {{ isFollowedPage ? 'mdi-account-multiple-minus' : 'mdi-account-multiple-plus' }}
        </v-icon>
      </template>
      <span>
        {{ isFollowedPage ? 'Unfollow' : 'Follow' }}
      </span>
    </app-action-btn>
  </fragment>
</template>
<script>
import {
  mapActions, mapGetters,
} from 'vuex';
import PageFollowWithWsSelection from './PageFollowWithWsSelection';
export default {
  name: 'PageFollow',
  components: {
    PageFollowWithWsSelection,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      canFollowPage: 'UserRoles/canFollowPage',
      workspaceSelectorOnPages: 'FeatureFlags/workspaceSelectorOnPages',
      getIdsOfMyPages: 'MyPages/getIdsOfMyPages',
    }),
    isFollowedPage() {
      const { id = '' } = this.item ?? {
      };
      return this.getIdsOfMyPages.includes(id);
    },
  },
  methods: {
    ...mapActions('MyPages', ['followPage', 'unfollowPage']),
    async onFollowActions() {
      const { allowed = false } = this.canFollowPage ?? {
      };
      if (!allowed) {
        return;
      }
      const { id: pageId = '', workspaceId: pageWorkspaceId = '' } = this.item ?? {
      };
      const params = {
        pageId,
        pageWorkspaceId,
      };
      this.isLoading = true;
      if (this.isFollowedPage) {
        await this.unfollowPage(params);
      } else {
        await this.followPage(params);
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
